import { AccountRecord } from '@ponylagoon/dto';
import { DeleteResult } from '../../models';

export const account = [
  '_id',
  'balance',
  'jived',
  'date',
  'name',
  'description',
  'account_type',
  'initialBalance',
];

export interface AccountSearch {
  aggregate?: Record<string, any>[];
  limit?: number;
  skip?: number;
  account_id?: string[];
}

export interface AccountCreate {
  initialBalance: number;
  date: string;
  description?: string;
  account_type: string;
  name: string;
}

export interface AccountChange {
  delete?: string[];
  update?: AccountCreate & { account_id: string };
}

export interface UpdateAccount {
  deleted?: DeleteResult;
  updated?: AccountRecord;
}
