export enum EAppRoutes {
  ROOT = '/',
  APP = 'app',
  DASHBOARD = 'dashboard',
  DRIVE = 'drive',
  UPLOAD = 'upload',
  FOLDER = 'folder',
  SPREADSHEET = 'sheet',
  GALLERY = 'gallery',
  STARRED = 'starred',
  RECYCLE_BIN = 'recyclebin',
  COLLECTION = 'collection',
  FEATURES = 'features',
  USER = 'u',
  PHOTO_EDITOR = 'photo-editor',
  SOURCES = 'sources',
  SEARCH = 'search',
  SIGNIN = 'signin',
  PROFILE = 'profile',
  REGISTER = 'register',
  TRANSACTIONS = 'transactions',
  MANAGE_ACCOUNTS = 'manage-accounts',
  MANAGE_PAYEES = 'manage-payees',
  VALIDATE_EMAIL = 'validate-email',
  PASSWORD_RESET = 'password-reset',
  FUTURE_TRANSACTIONS = 'upcoming-transactions',
  PRIVACY = 'privacy-policy',
}

export const EAppFullRoutes = {
  DRIVE: [EAppRoutes.ROOT, EAppRoutes.DASHBOARD, EAppRoutes.DRIVE],
  APP: [EAppRoutes.ROOT, EAppRoutes.APP],
  PROFILE: [EAppRoutes.ROOT, EAppRoutes.APP, EAppRoutes.PROFILE],
  SIGNIN: [EAppRoutes.ROOT, EAppRoutes.SIGNIN],
  DASHBOARD: [EAppRoutes.ROOT, EAppRoutes.APP, EAppRoutes.DASHBOARD],
  LANDING: [EAppRoutes.ROOT, EAppRoutes.DASHBOARD, EAppRoutes.DRIVE],

  TRANSACTIONS: [
    EAppRoutes.ROOT,
    EAppRoutes.APP,
    EAppRoutes.MANAGE_ACCOUNTS,
    EAppRoutes.TRANSACTIONS,
  ],
  FUTURE_TRANSACTIONS: [EAppRoutes.FUTURE_TRANSACTIONS],
  MANAGE_ACCOUNTS: [
    EAppRoutes.ROOT,
    EAppRoutes.APP,
    EAppRoutes.MANAGE_ACCOUNTS,
  ],
  MANAGE_PAYEES: [EAppRoutes.ROOT, EAppRoutes.APP, EAppRoutes.MANAGE_PAYEES],
  USER: (username) => ['', EAppRoutes.USER, username].join('/'),
};

export const FUTURE_TRANSACTIONS = [
  ...EAppFullRoutes.TRANSACTIONS,
  EAppRoutes.FUTURE_TRANSACTIONS,
];
