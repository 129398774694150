import { Injectable } from '@angular/core';
import { GraphqlService } from '../graphql';
import Fields from 'gql-query-builder/build/Fields';
import { Observable, of } from 'rxjs';
import { omitBy, isNil } from 'lodash';
import {
  transaction,
  TransactionChange,
  TransactionCreate,
  transactionRangeModel,
  TransactionRangeSearch,
  TransactionSearch,
  transactionSearchResults,
  UpdateTransaction,
} from '../graphql/fields/transaction';
import { Transaction } from '../models/Transaction';
import { TransactionRage } from '../models/TransactionRange';
import { Payee } from '../models/Payee';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private graphqlService: GraphqlService) {}

  getRange(
    search: TransactionRangeSearch,
    fields: Fields = [],
  ): Observable<TransactionRage> {
    return this.graphqlService.query({
      operation: 'transactionRange',
      fields: [...transactionRangeModel, ...fields],
      variables: {
        search: {
          value: search,
          required: true,
          type: 'TransactionRangeSearch',
        },
      },
    });
  }

  getDistinctDescription(): Observable<string[]> {
    return this.graphqlService.query({
      operation: 'distinctTransactions',
      fields: [],
      variables: {
        distinct: {
          value: 'description',
          required: true,
          type: 'String',
        },
      },
    });
  }

  createTransaction(
    create: TransactionCreate,
    fields: Fields = [],
  ): Observable<Transaction | Payee> {
    const keyTypes = {
      recurring: 'ETransactionRecurringTypes',
      status: 'ETransactionStatus',
      transaction_type: {
        type: 'ETransactionTypes',
        required: true,
      },
      numInstallments: 'Float',
      checkNumber: 'Float',
      payee_id: 'ID',
      account_id: 'ID',
    };

    const variables = omitBy(
      {
        ...create,
        ...this.graphqlService.getVariables(create, keyTypes),
        ...(create?.items
          ? {
              items: {
                value: create.items,
                type: 'TransactionItemsInputType',
                list: true,
              },
            }
          : {}),
        ...(create?.file_id
          ? {
              file_id: {
                value: create.file_id,
                type: 'ID',
                list: true,
              },
            }
          : {}),
        date: { value: create.date, required: true },
        amount: {
          value: Number(create.amount),
          required: true,
          type: 'Float',
        },
      },
      isNil,
    );

    return this.graphqlService.mutate({
      operation: 'createTransaction',
      fields: [...transaction, ...fields],
      variables,
    });
  }

  getTransactions(
    search: TransactionSearch,
    fields: Fields = [],
  ): Observable<{ data: Transaction[]; cursor?: Record<string, number> }> {
    return this.graphqlService.query({
      operation: 'getTransactions',
      fields: [...transactionSearchResults, ...fields],
      variables: {
        search: {
          value: search,
          required: true,
          type: 'TransactionSearch',
        },
      },
    });
  }

  updateTransaction(
    variables: TransactionChange,
    fields: Fields = [],
  ): Observable<UpdateTransaction> {
    return this.graphqlService.mutate({
      operation: 'updateTransaction',
      fields: [{ updated: transaction }, ...fields],
      variables: {
        change: {
          value: variables,
          required: true,
          type: 'TransactionChange',
        },
      },
    });
  }
}
