import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  account_details,
  authenticated_client,
  GraphqlService,
  settings,
} from '../graphql';
import { AppState } from '../app.service';
import {
  publishModel,
  PublishPolicy,
  PublishSearch,
} from '../graphql/fields/permission';
import { Observable } from 'rxjs';
import Fields from 'gql-query-builder/build/Fields';
import { IPrivacySetting } from '../models';
import { AuthenticatedClientModel } from '../models/AuthenticatedClientModel';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private graphqlService: GraphqlService,
    private appState: AppState,
  ) {}

  getFilePermissions(
    search: PublishSearch,
    fields: Fields = [],
  ): Observable<PublishPolicy[]> {
    return this.graphqlService.query({
      operation: 'getFilePermissions',
      fields: [...publishModel, ...fields],
      variables: {
        search: { value: search, required: true, type: 'PublishSearch' },
      },
    });
  }

  publish(publish: PublishPolicy): Observable<PublishPolicy> {
    return this.graphqlService.mutate({
      operation: 'publish',
      fields: [...publishModel],
      variables: {
        ...publish,
        emails: {
          value: publish.emails,
          list: [true],
        },
        file_id: {
          value: publish.file_id,
          required: true,
        },
        grant_access: {
          value: publish.grant_access,
          required: true,
          type: 'EGrantAccessType',
        },
      },
    });
  }

  getSettings(): Observable<IPrivacySetting> {
    return <Observable<IPrivacySetting>>this.graphqlService
      .query<{ authenticated_client: AuthenticatedClientModel }>({
        operation: 'ping',
        fields: [{ authenticated_client: [{ settings }] }],
      })
      .pipe(map(({ authenticated_client }) => authenticated_client?.settings));
  }

  ping(fields = []) {
    return this.graphqlService
      .query<{
        authenticated_client: AuthenticatedClientModel;
        account_details: Record<string, any>;
      }>({
        operation: 'ping',
        fields: [{ authenticated_client }],
      })
      .pipe(
        map(({ authenticated_client, account_details }) => {
          this.appState.set('account_details', account_details);
          return authenticated_client;
        }),
      );
  }

  authenticate(email, password) {
    return this.graphqlService.query<{
      authenticated_client: AuthenticatedClientModel;
    }>({
      operation: 'auth',
      fields: [{ authenticated_client }],
      variables: {
        email: { value: btoa(email), required: true, type: 'Base64String' },
        password: {
          value: btoa(password),
          required: true,
          type: 'Base64String',
        },
      },
    });
  }
}
