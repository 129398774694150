import { Payee } from '../../models/Payee';
import { TransactionCreate } from './transaction';
import { DeleteResult } from '../../models';

export const payee = [
  '_id',
  'account_id',
  'amount',
  'category',
  'date',
  'description',
  'memo',
  'recurring',
  'autoPay',
  'active',
  'hyperlink',
  'transaction_type',
];

export const payeeSearchResults = [{ data: payee }];
export const payeeCursor = ['count', 'size', 'totalPages'];

export interface PayeeSearch {
  aggregate?: Record<string, any>[];
  limit?: number;
  skip?: number;
  payee_id?: string[];
  date?: string;
  account_id?: string;
}

export interface PayeeChange {
  delete?: string[];
  update?: TransactionCreate & {
    hyperlink?: string;
    payee_id: string;
    active?: boolean;
  };
}

export interface UpdatePayee {
  deleted?: DeleteResult;
  updated?: Payee;
}
