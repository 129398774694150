import { Transaction } from '../../models/Transaction';
import { DeleteResult } from '../../models';
import { ETransactionStatus } from '@ponylagoon/dto';
import { photoSwipe } from './files';

export const transaction = [
  '_id',
  'account_id',
  'amount',
  'category',
  'date',
  'description',
  'transaction_type',
  'balance',
  'clear',
  'checkNumber',
  'memo',
  'payee_id',
  'hyperlink',
  { items: ['key', 'value'] },
  { file: ['_id', 'originalname', ...photoSwipe] },
];

export const deleted = ['acknowledged', 'deletedCount'];

export const transactionRangeModel = [
  'dates',
  {
    data: [
      {
        dates: [
          'date',
          {
            transaction: [
              'amount',
              'transaction_type',
              'account_id',
              'date',
              'description',
              { records: [...transaction, 'status'] },
            ],
          },
        ],
      },
      '_id',
      'hyperlink',
      'description',
    ],
  },
  {
    footer: [{ dates: ['amount', 'date'] }, 'description'],
  },
];

export const transactionSearchResults = [{ data: transaction }];
export const transactionCursor = { cursor: ['count', 'size', 'totalPages'] };

export interface TransactionSearch {
  aggregate?: Record<string, any>[];
  limit?: number;
  skip?: number;
  transaction_id?: string[];
  date?: string;
  account_id?: string;
}

export interface TransactionChange {
  delete?: string[];
  update?: TransactionCreate & { transaction_id: string };
}

export interface UpdateTransaction {
  deleted?: DeleteResult;
  updated?: Transaction;
}

export interface TransactionRangeSearch {
  account_id: string;
  endDate: string;
  groupBy: string;
  startDate: string;
}

export interface transactionItemTypes {
  key: string;
  value: string;
}

export interface TransactionCreate {
  account_id?: string;
  amount?: number;
  numInstallments?: number;
  category?: string;
  checkNumber?: number;
  clear?: boolean;
  date?: string;
  transfer?: string;
  description?: string;
  file_id?: string[];
  items?: transactionItemTypes[];
  memo?: string;
  payee_id?: string;
  status?: ETransactionStatus;
  recurring?: string;
  transaction_type?: string;
}
