import { Injectable } from '@angular/core';
import { GraphqlService } from '../graphql';
import Fields from 'gql-query-builder/build/Fields';
import { Observable, of } from 'rxjs';
import {
  payee,
  PayeeChange,
  PayeeSearch,
  payeeSearchResults,
  UpdatePayee,
} from '../graphql/fields/payee';
import { Payee } from '../models/Payee';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PayeeService {
  constructor(private graphqlService: GraphqlService) {}

  getPayees(
    search: PayeeSearch,
    fields: Fields = [],
  ): Observable<{ data: Payee[]; cursor?: Record<string, number> }> {
    return this.graphqlService.query({
      operation: 'getPayees',
      fields: [...payeeSearchResults, ...fields],
      variables: {
        search: {
          value: search,
          required: true,
          type: 'PayeeSearch',
        },
      },
    });
  }

  getPayee(payee_id: string, fields: Fields = []): Observable<Payee> {
    return this.graphqlService
      .query({
        operation: 'getPayees',
        fields: [...payeeSearchResults, ...fields],
        variables: {
          search: {
            value: {
              payee_id: [payee_id],
            },
            required: true,
            type: 'PayeeSearch',
          },
        },
      })
      .pipe(
        map(({ data: [a] }) => a),
        catchError((error: HttpErrorResponse) => of(undefined)),
      );
  }

  updatePayee(
    variables: PayeeChange,
    fields: Fields = [],
  ): Observable<UpdatePayee> {
    return this.graphqlService.mutate({
      operation: 'updatePayee',
      fields: [{ updated: payee }, ...fields],
      variables: {
        change: {
          value: variables,
          required: true,
          type: 'PayeeChange',
        },
      },
    });
  }
}
