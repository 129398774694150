import { Injectable } from '@angular/core';
import { GraphqlService } from '../graphql';
import Fields from 'gql-query-builder/build/Fields';
import { Observable } from 'rxjs';
import { omitBy, isNil } from 'lodash';
import {
  account,
  AccountChange,
  AccountCreate,
  AccountSearch,
  UpdateAccount,
} from '../graphql/fields/account';
import { Account } from '../models/Account';
import { AccountRecord } from '@ponylagoon/dto';
import { UpdateTransaction } from '../graphql/fields/transaction';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  constructor(private graphqlService: GraphqlService) {}

  createAccount(
    create: AccountCreate,
    fields: Fields = [],
  ): Observable<Account> {
    const account_type = create?.account_type
      ? {
          value: create.account_type,
          required: true,
          type: 'EAccountTypes',
        }
      : undefined;

    const variables = omitBy(
      {
        ...create,
        account_type,
        date: {
          value: create.date,
          required: true,
        },
        initialBalance: {
          value: Number(create.initialBalance),
          required: true,
          type: 'Float',
        },
      },
      isNil,
    );

    return this.graphqlService.mutate({
      operation: 'createAccount',
      fields: [...account, ...fields],
      variables,
    });
  }

  updateAccount(
    variables: AccountChange,
    fields: Fields = [],
  ): Observable<UpdateAccount> {
    return this.graphqlService.mutate({
      operation: 'updateAccount',
      fields: [{ updated: account }, ...fields],
      variables: {
        change: {
          value: variables,
          required: true,
          type: 'AccountChange',
        },
      },
    });
  }

  getAccounts(
    search: AccountSearch,
    fields: Fields = [],
  ): Observable<AccountRecord[]> {
    return this.graphqlService.query({
      operation: 'getAccounts',
      fields: [...account, ...fields],
      variables: {
        search: {
          value: search,
          required: true,
          type: 'AccountSearch',
        },
      },
    });
  }
}
