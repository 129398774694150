import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable()
export class DownloadService {
  constructor(private http: HttpClient) {}

  getBlob(url) {
    return this.http
      .get(url, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((res) => {
          const blob = res.body;
          const contentDisposition =
            res.headers.get('Content-Disposition') ?? undefined;
          const contentType = res.headers.get('content-type') ?? undefined;
          const originalname =
            res.headers.get('content-originalname') ?? undefined;
          const filename = originalname ?? this.getFileName(contentDisposition);
          return {
            contentDisposition,
            contentType,
            blob,
            filename,
          };
        }),
      );
  }

  public getFileName(disposition: string): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = null;
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }

  download(url): Observable<any> {
    return this.getBlob(url).pipe(
      tap((d) => {
        const { blob, filename } = d;
        saveAs(blob, filename);
      }),
    );
  }
}
